@import '../../styles/variables';

.title {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 10px;
  padding-left: 15px;
  
  @media (max-width: $breakpoint-sm) {
    font-size: 25px;
  }
}