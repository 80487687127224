.no-icon {
    display: none!important;
}

.rct-icon {
    margin-right: 0.2em;
}

.rct-node-icon {
    display: none!important;
    margin-right: 1em;
}