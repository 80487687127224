@import '../../styles/variables.scss';


.circular-progress {
    position: relative;
}

.inner,
.outer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
}

.inner {
    border: 4px solid $primary-blue;
    border-top-color: $white-cream;
    animation: spin 1s linear infinite;
}

.outer {
    border: 2px solid $translucent-white;
    border-top-color: $white-cream;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}