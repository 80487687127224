@import 'variables.scss';

html, body, #root {
  height: 100%;
}

.container-fluid {
  min-height: calc(100% - 250px);
  position: relative;
}

.hidden {
  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

.primary-text-color {
  color: $primary-blue;
}

.error {
  color: $error-red;
}

.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  font-size: 18px;
  color: white;
  background-color: red;
  border-radius: 20px;
}

td, th {
  cursor: default;
}

.notification-bell {
  @media (max-width: $breakpoint-sm) {
    position: absolute;
    top: 0;
    right: 0;
  }
  position: absolute;
  top: -5px;
  right: -5px;
}

a.link {
  color: $primary-blue !important;
  text-decoration: underline !important;
  cursor: pointer;
  font-size: 14px;

  &.disabled {
    color: $medium-grey !important;
    cursor: default;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

label {
  margin-bottom: 0;
}

.gap-x-2 {
  gap: 0.5rem;
}

progress[value] {
  height: 20px;
}

.fit-content {
  width: fit-content;
}