@import '../../../styles/variables.scss';

.group-edition {

    tr:hover {
        cursor: pointer;
        background-color: $light-blue !important;
    }

    .group-table-page {
        color: $primary-blue;
    }
}