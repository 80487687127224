.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;

  .spinner {
    margin: auto;
  }
}

.overlay-wrapper {
  @extend .overlay;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__button {
    &--small {
      width: 50px;
      height: 45px;
      border-radius: 4px;
    }
  }
}