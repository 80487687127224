@import '../../styles/forms';
@import '../../styles/variables';

.help {
  height: calc(100% - 250px);
  font-size: 18px;

  ul {
    margin: 20px;

    li {
      a {
        color: $primary-grey;
      }
    }
  }
}