@import '../../styles/variables.scss';

.react-autosuggest__input {
  width: 100%;
  min-height: 50px;
  padding: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border: 0 !important;
  border-radius: 8px;
  outline: none;
  margin-bottom: 1rem;
}

.react-autosuggest__suggestions-list {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  padding-right: 10px;

  .react-autosuggest__suggestion {
    line-height: 35px;
    list-style: none;
    cursor: pointer;

    &:hover {
      color: $primary-blue;
    }
  }
}