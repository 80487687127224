@import '../../styles/forms';
@import '../../styles/variables';

.logo {
  width: auto;
  max-height: 350px;

  @media (max-width: $breakpoint-sm) {
    max-height: 200px;
  }
}