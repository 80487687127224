@import '../../../styles/variables.scss';
.configuration {
  min-height: 55px;
}

.user-edition {
  tr {
    border-radius: 10px;
  }

  tr:hover {
    cursor: pointer;
    background-color: $light-blue !important;
  }

  .group-table-page {
    color: $primary-blue;
  }
}

.react-autosuggest__suggestions-container {
  background-color: white !important;
}

.form-control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
