@import '../../styles/forms';
@import '../../styles/variables';


.logo-container {
  text-align: center;
}

.date-input-container {
  max-width: 250px;
}

.map-container {
  height: 400px;
  margin-bottom: 100px;
}

.text-title {
  font-weight: bold;
  color: $primary-blue;
}

.form-check-inline {
  padding-right: 110px;
}

.input-text-title {
  color: $medium-grey;
}

.input-subtext-title {
  font-size: 14px;
  font-style: italic;
  color: $primary-blue;
  cursor: pointer;
}

.submit-form-footer {
  text-align: center;
  color: red;
}

sup[class^="asterisk"] {
  color: red;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #bbbbbb;
  border-radius: 50%;
  cursor: pointer;
}

input[type="radio"]:checked {
  border-color: $primary-blue;
  border-width: 5px;
}

.form-check-label {
  cursor: pointer;
}

.mb-6 {
  margin-bottom: 60px;
}

