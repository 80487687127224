@import '../../styles/forms';
@import '../../styles/variables';

.contributor-container {
  padding-right: 20px;
}

.map-display {
  margin: 0;
  height: 400px;
  @media (min-width: $breakpoint-md) {
    height: 600px;
  }
  @media (min-width: $breakpoint-lg) {
    height: 800px;
  }
}

.h-90 {
  height: 90%;
}

.icon-card-container {
  position: absolute;
  font-size: 20px;
  border-radius: 20px;
  height: 25px;
  width: 25px;
  margin-top: 6px;
  margin-left: 12px;
  padding-top: 2px;
  padding-left: 2px;
}

.marker-container {
  width: 50px;
  height: 50px;
  position: relative;
  // on retranche la hauteur et la largeur / 2 du pin pour l'aligner sur la localisation exacte
  top: -50px;
  left: -25px;
}

.pin-map {
  font-size: 50px;
  color: $primary-blue;
}
