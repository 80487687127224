.footer {
  width: 100%;
  height: 250px;
  background: url('../../resources/assets/home-background.png') no-repeat;
  background-size: cover;
  z-index: -1;

  &.big {
    position: fixed;
    bottom: 0;
    height: 60%;
  }
}