.custom-modal {
    font-size: 12px;
}
.custom-modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}
.custom-modal > .content {
    width: 100%;
    padding: 10px 5px;
}
.custom-modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}
.custom-modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.icon {
    height: 22px;
    width: 22px;
    cursor: pointer;
    padding: 5px;
    border: 1px solid;
    border-radius: 20px;
    margin-left: 10px;
}

.modalInfos {
    padding: 20px;
    font-size: 16px;
}