@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?3zh4y');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?3zh4y#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?3zh4y') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?3zh4y') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?3zh4y##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xsl {
  &:before {
    content: $icon-xsl; 
  }
}
.icon-pictoTab {
  &:before {
    content: $icon-pictoTab;     
    color: #2255a5;
  }
}
.icon-pictoSearch {
  &:before {
    content: $icon-pictoSearch;     
    color: #2255a5;
  }
}
.icon-pictoInterieur {
  &:before {
    content: $icon-pictoInterieur;     
    color: #fff;
  }
}
.icon-pictoBailleur {
  &:before {
    content: $icon-pictoBailleur;     
    color: #fff;
  }
}
.icon-pictoExterieur {
  &:before {
    content: $icon-pictoExterieur;     
    color: #fff;
  }
}
.icon-pictoLogement {
  &:before {
    content: $icon-pictoLogement;     
    color: #fff;
  }
}
.icon-pictoCommerce {
  &:before {
    content: $icon-pictoCommerce;     
    color: #fff;
  }
}
.icon-pictoTrafics {
  &:before {
    content: $icon-pictoTrafics;     
    color: #fff;
  }
}
.icon-pictoService {
  &:before {
    content: $icon-pictoService;     
    color: #fff;
  }
}
.icon-pictoDegradations {
  &:before {
    content: $icon-pictoDegradations;     
    color: #fff;
  }
}
.icon-pictoPersonnes {
  &:before {
    content: $icon-pictoPersonnes;     
    color: #fff;
  }
}
.icon-pictoMaison {
  &:before {
    content: $icon-pictoMaison; 
  }
}
.action-bar .icon-csv {
  &:before {
    font-size: 45px;
    content: $icon-csv; 
  }
}

.patrimony .icon-csv {
  &:before {
    font-size: 25px;
    content: $icon-csv; 
  }
}

.icon-pdf {
  &:before {
    font-size: 45px;
    content: $icon-pdf; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-up {
  &:before {
    content: $icon-up; 
  }
}
.icon-down {
  &:before {
    content: $icon-down; 
  }
}
.icon-less {
  &:before {
    content: $icon-less; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-more {
  &:before {
    content: $icon-more; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-help {
  &:before {
    content: $icon-help;     
    color: #2255a5;
  }
}
.icon-bar-chart {
  &:before {
    content: $icon-bar-chart;     
    color: #2255a5;
  }
}
.icon-map {
  &:before {
    content: $icon-map;     
    color: #2255a5;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;     
    color: #fff;
  }
}
.icon-pictoPhysiques {
  &:before {
    content: $icon-pictoPhysiques;     
    color: #fff;
  }
}
.icon-pictoVoisinage {
  &:before {
    content: $icon-pictoVoisinage;     
    color: #fff;
  }
}
.icon-pictoJouissance {
  &:before {
    content: $icon-pictoJouissance;     
    color: #fff;
  }
}
.icon-pictoOccupation {
  &:before {
    content: $icon-pictoOccupation;     
    color: #fff;
  }
}
.icon-pictoCommuns {
  &:before {
    content: $icon-pictoCommuns;     
    color: #fff;
  }
}
.icon-pictoBiens {
  &:before {
    content: $icon-pictoBiens;     
    color: #fff;
  }
}
.icon-pictoVerbales {
  &:before {
    content: $icon-pictoVerbales;     
    color: #fff;
  }
}
.icon-angle-double-left {
  &:before {
    content: $icon-angle-double-left; 
  }
}
.icon-angle-double-right {
  &:before {
    content: $icon-angle-double-right; 
  }
}
.icon-angle-left {
  &:before {
    content: $icon-angle-left; 
  }
}
.icon-angle-right {
  &:before {
    content: $icon-angle-right; 
  }
}

