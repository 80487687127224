@import '../../styles/variables';

.card-status {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
  font-size: 14px;

  .label {
    width: 80px;
    line-height: 24px;
    border-radius: 4px;
    color: white;
    background-color: $light-green;
    font-size: 13px;
    text-align: center;
  }

  .desc {
    width: 100%;

    .form-group {
      background-color: $white-cream;
      width: 100%;

      .form-label {
        padding: 10px;
        padding-bottom: 0;
        margin-bottom: 0;
        color: $secondary-grey;
      }

      textarea.form-control {
        background-color: $white-cream;
        box-shadow: none;
        border-radius: 0;
        font-size: 13px;
      }
    }
  }
}
