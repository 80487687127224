@import '../../styles/variables.scss';

.notification-background {
  background-color: $primary-blue-light !important;
}

.event {
  position: relative;
  width: 100%;
  height: 115px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  font-size: 12px;
  margin-bottom: 25px;
  cursor: pointer;

  @media (max-width: $breakpoint-lg) {
    height: 140px;
  }

  @media (max-width: $breakpoint-sm) {
    height: 220px;
  }

  .row {
    padding-top: 10px;
    padding-bottom: 10px;

    @media (max-width: $breakpoint-sm) {
      padding-bottom: 20px;
    }
  }

  .type-logo {
    padding-top: 5px;

    div[class^="icon-"] {
      background-color: $primary-blue;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 100%;
      font-size: 28px;
      text-align: center;

      @media (max-width: $breakpoint-md) {
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 22px;
      }
    }
  }

  .subtitle {
    color: $medium-grey;
  }

  .event-id {
    font-weight: 700;
  }

  .status {
    position: absolute;
    top: -13px;
    right: 20px;
    width: 80px;
    line-height: 24px;
    border-radius: 4px;
    color: white;
    background-color: $light-green;
    font-size: 13px;
    text-align: center;
  }

  .author {
    position: absolute;
    bottom: 5px;
    right: 20px;
    color: $medium-grey;
    font-style: italic;
  }

  .pj-info {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 25px;
    height: 25px;
  }

}