@import '../../styles/variables';

.logo-small {
  display: block;
  width: auto;
  max-height: 100px;
  margin: auto;
  cursor: pointer;
}

.no-result {
  color: $medium-grey;
  text-align: center;

  .icon-warning:before {
    color: $medium-grey;
    font-size: 70px;
  }
}

.filters {
  @media (max-width: $breakpoint-md) {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    background: white;
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
  }
}

.modal-export {
  a:hover {
    text-decoration: none;
  }

  span[class^="icon-"] {
    font-size: 65px;
    cursor: pointer;
  }
  
  .icon-container {
    display: flex;
    align-items: center;
  }

  .icon-pdf {
    color: $red;
  }

  .icon-csv {
    color: $green;
  }

  .spinner-pdf {
    position: relative;

    .spinner-border {
      position: absolute;
      top: -26px;
      left: 18px;
    }

    .icon-pdf {
      opacity: 0.5;
    }
  }
}

.icon-export {
  max-height: 3px;
}