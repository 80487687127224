@import '../../styles/variables.scss';

.dropzone {
    border-radius: 8px;
    background-color: rgba(248,249,250,255);
    padding: 1em;
    margin-top: 1em;
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &-container {
        width: 400px;
        display: flex;
        flex-direction: column;
        
        @media (max-width: $breakpoint-sm) {
            width: 100%;
        }
    }

    &--disabled {
        cursor: not-allowed;
    }

    &-file {
        padding: 0.5em;
        margin-top: 0.5em;
        background-color: $white-smoke;
        border-radius: 8px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        &-text {
            color: $primary-blue;
            text-decoration: underline;
            cursor: pointer;
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &-placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2em;
        flex: 1;
        gap: 1.5em;
        &-link {
            color: $primary-blue;
            text-decoration: underline;
            cursor: pointer;
        }

        &-icon {
            font-size: 2em;
            color: $primary-blue;
        }
    }
}