@import '../../styles/variables';

.card-container {
  padding-top: 1rem;
  margin: 15px;
  height: 200px;
  width: 200px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  text-align: center;

  @media (max-width: $breakpoint-sm) {
    height: 100% !important;
    width: 100% !important;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 0px;
    margin-left: 0px;
  }

  span[class^="icon-"] {
    &.icon-card {
      &:before {
        font-size: 40px;
        color: $primary-blue;
      }
    }
  }
}

.edit {
  padding: 0;
  height: 170px;
  width: 170px;
  display: flex;
  flex-direction: column;

  span[class^="icon-"] {
    &.icon-card {
      &:before {
        font-size: 60px;
        color: black;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    height: 150px;
    width: 150px;
    span[class^="icon-"] {
      &.icon-card {
        &:before {
          font-size: 40px;
        }
      }
    }
    .description-card {
      font-size: 13px;
    }
  }
}

.inline {
  display: flex;
  width: 50%;
  height: auto;
  padding: 10px 20px;
  margin-bottom: 0;
  border-radius: 8px;
  span[class^="icon-"] {
    &.icon-card {
      &:before {
        font-size: 35px;
        color: black;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    width: 100%;
  }
  @media (max-width: $breakpoint-sm) {
    margin-bottom: 5px;
  }

  .inline-title {
    text-align: left;
    padding-left: 10px;
    margin-right: 30px;
  }

  .center-item {
    align-items: center;
    display: flex;
  }
}

.card-container:hover,
.selected-card {
  background-color: $primary-blue;
  cursor: pointer;

  .title-card,
  .description-card {
    color: white;
  }
  span[class^="icon-"] {
    &.icon-card {
      &:before {
        color: white;
      }
    }
  }
}

.noHover{
  pointer-events: none;
}

.title-card {
  color: $primary-blue;
  font-weight: bold;
  font-size: 18px;
}

.content-card {
  padding: 5px;
  margin-bottom: 1rem;
}

.description-card {
  font-size: 15px;
}

.icon-container {
  padding-top: 35px;
}
