@import '../../styles/variables.scss';

.configuration {
  position: relative;
  width: 100%;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  font-size: 12px;
  margin-bottom: 25px;
  cursor: pointer;
  min-height: 75px;

  .category-title {
    color: $primary-blue;
    font-weight: bold;
    font-size: 18px;
  }

  .category-subtitle {
    font-size: 16px;
  }
}

.accordion {
  width: 100%;
  cursor: pointer;

  .card-header {
    background-color: $white-cream;

    span {
      line-height: 45px;
    }
  }

  .card-body {
    padding: 15px;
  }
}

.estate-configuration {
  .icon-csv {
    font-size: 25px;
  }
}

.text {
  color: $primary-blue
}

.patrimony-link {
  &:hover {
    color: $primary-blue;
  }
  cursor: pointer;
}

:export { primary: $primary-blue; secondary: $light-grey }