$primary-blue: #254c8e;
$primary-blue-light: #254c8e1A;
$secondary-blue: #317ef6;
$primary-grey: #3a3a3a;
$secondary-grey: #6c6d70;
$medium-grey: #979797;
$light-grey: #d0d0d0;
$white-smoke: #f0f0f0;
$white-cream: #fbfbfb;
$translucent-white: rgba(255, 255, 255, 0.5);
$light-blue: #e7f0fe;
$light-green: #4fcdc4;
$green: #08743b;

$red: #ea4c3a;
$error-red: #CC0000;
$danger-red: #b71111;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;

:export { 
    primary: $primary-blue;
    secondary: $light-grey;
    primaryGrey: $primary-grey;
    secondaryGrey: $secondary-grey;
    mediumGrey: $medium-grey;
    lightGrey: $light-grey;
    whiteSmoke: $white-smoke;
    whiteCream: $white-cream;
    translucentWhite: $translucent-white;
    lightBlue: $light-blue;
    lightGreen: $light-green;
    green: $green;
    red: $red;
    errorRed: $error-red;
    breakpointSm: $breakpoint-sm;
    breakpointMd: $breakpoint-md;
    breakpointLg: $breakpoint-lg;
}