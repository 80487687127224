@import '../../styles/variables';

.btn {
  height: 50px;
  width: 100%;
  border-radius: 30px !important;
}

.primary {
  background-color: $primary-blue !important;
  border-color: $primary-blue !important;
}

.secondary {
  background-color: $light-grey !important;
  border-color: $light-grey !important;
}

.danger {
  background-color: $danger-red !important;
  border-color: $danger-red !important;
}

.small {
  width: 250px;
}
