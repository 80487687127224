@import './variables';

.form-control, .form-control-file {
  min-height: 50px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  border: 0 !important;
}

.form-control[readonly] {
  background-color: $white-cream !important;
}

.form-control-file::file-selector-button {
  color: #212529;
  background-color: #e9ecef;
  border: 0 solid;
  cursor: pointer;
  min-height: 50px;
}

.btn-back {
  background-color: $light-grey !important;
  color: white !important;
  height: 50px;
  width: 100%;
  border-radius: 30px !important;
  border: none !important;
}

.action-button {
  background: $white-cream;
  color: black;
  font-size: 14px;
  border: 1px solid $light-grey;
  padding: 10px;
  width: 150px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;

  &.disabled {
    background: $light-grey;
    color: white;
    cursor: not-allowed;
  }

  &.small {
    width: 50px;
    height: 45px;
    text-align: center;

    .fa {
      padding-right: 0;
      font-size: 16px;
      color: black;
      line-height: 25px;
    }

    &:hover:not(.disabled), &.active:not(.disabled) {
      .fa {
        color: white;
      }
    }
  }

  &:hover:not(.disabled), &.active:not(.disabled) {
    background: $primary-blue;
    color: white;
  }

  .fa {
    padding-right: 10px;
    font-size: 16px;
  }

  .fa-sync {
    &:before {
      content: "\f021";
    }
  }
}

.rw-multiselect>.rw-widget-picker {
  min-height: 50px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  border: 0 !important;
  margin-bottom: 1rem !important;
}

.rw-state-disabled>.rw-widget-picker {
  background-color: $white-cream !important;
}