$icomoon-font-family: "orfi" !default;
$icomoon-font-path: "orfi" !default;

$icon-xsl: unquote('"\\e905"');
$icon-pictoTab: unquote('"\\e90e"');
$icon-pictoSearch: unquote('"\\e91d"');
$icon-pictoInterieur: unquote('"\\e90b"');
$icon-pictoBailleur: unquote('"\\e906"');
$icon-pictoExterieur: unquote('"\\e918"');
$icon-pictoLogement: unquote('"\\e91a"');
$icon-pictoCommerce: unquote('"\\e91c"');
$icon-pictoTrafics: unquote('"\\e91f"');
$icon-pictoService: unquote('"\\e920"');
$icon-pictoDegradations: unquote('"\\e921"');
$icon-pictoPersonnes: unquote('"\\e919"');
$icon-pictoMaison: unquote('"\\e91b"');
$icon-csv: unquote('"\\e915"');
$icon-pdf: unquote('"\\e916"');
$icon-location: unquote('"\\e917"');
$icon-up: unquote('"\\e910"');
$icon-down: unquote('"\\e911"');
$icon-less: unquote('"\\e912"');
$icon-close: unquote('"\\e913"');
$icon-more: unquote('"\\e914"');
$icon-settings: unquote('"\\e90f"');
$icon-help: unquote('"\\e904"');
$icon-bar-chart: unquote('"\\e90a"');
$icon-map: unquote('"\\e90c"');
$icon-warning: unquote('"\\e90d"');
$icon-pictoPhysiques: unquote('"\\e908"');
$icon-pictoVoisinage: unquote('"\\e900"');
$icon-pictoJouissance: unquote('"\\e901"');
$icon-pictoOccupation: unquote('"\\e902"');
$icon-pictoCommuns: unquote('"\\e903"');
$icon-pictoBiens: unquote('"\\e907"');
$icon-pictoVerbales: unquote('"\\e909"');
$icon-angle-double-left: unquote('"\\f100"');
$icon-angle-double-right: unquote('"\\f101"');
$icon-angle-left: unquote('"\\f104"');
$icon-angle-right: unquote('"\\f105"');

