@import '../../styles/variables.scss';

.dashboard {
  .container {
    max-width: 90%;
  }

  .total-tile {
    position: absolute;
    right: 0;
    box-shadow: 0 2px 20px -5px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 15px;
    min-width: 250px;

    @media (max-width: $breakpoint-md) {
      margin-top: 35px;
    }

    .total-number {
      font-weight: bold;
      font-size: 24px;
    }
  }

  .charts {
    margin-top: 75px;
  }
  .dashboard-tile {
    min-height: 400px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 2px 20px -5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;

    &.progress-tile {
      min-height: 200px;

      .key-number {
        font-size: 40px;
        font-weight: bold;
        text-align: center;
      }

      .number {
        font-weight: bold;
        font-size: 20px;
        padding-right: 5px;
      }
    }

    .legend {
      font-size: 13px;

      .bold {
        font-weight: bold;
      }
    }

    .evolution-chart {
      height: 250px;
    }

    .pie-chart {
      height: 300px;
    }

    .recharts-legend-item-text {
      font-size: 10px;
    }

    .custom-select {
      font-size: 12px;
      border: none;
      color: black;
      font-weight: bold;
    }

    .switch-chart {
      font-size: 14px;
      cursor: pointer;

      .active {
        color: $primary-blue;
        text-decoration: underline;
      }
    }
  }

  .progress {
    height: 5px !important;

    .progress-bar {
      background: linear-gradient(0.25turn, $primary-blue, $secondary-blue);
    }
  }

  .progress-start {
    float: left;
    font-size: 12px;
    color: $primary-blue;
  }

  .progress-end {
    float: right;
    text-align: right;
    font-size: 12px;
    color: $light-grey;
  }
}

.custom-tooltip {
  background-color: $primary-grey;
  color: white;
  box-shadow: 0 2px 20px -5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
}