@import '../../styles/variables.scss';

.chips {
  background-color: $white-smoke;
  border-radius: 25px;
  line-height: 30px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;

  .icon-close {
    cursor: pointer;
  }
}

.header-criteria {
  font-weight: bold;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $light-grey;
  cursor: pointer;

  span[class^="icon-"] {
    font-weight: bold;
    font-size: 22px;
  }
}

.patrimony-filter-node {
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  .rct-text {
    label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.patrimony-filter-node:hover {
  overflow: visible;
}

.react-checkbox-tree {
  .rct-title {
    padding: 0 !important;
  }

  .rct-checkbox {
    padding-right: 0 !important;
  }

  .fa-check-square {
    color: $primary-blue !important;
  }

  label:hover {
    background: none ! important;
  }
}


input[type=checkbox].filter-checkbox { display:none; }

input[type=checkbox].filter-checkbox + label {
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 0;
  padding-left: 10px;

  &:before {
    font-family: FontAwesome;
    display: inline-block;
    padding-right: 10px;
    content: "\f096";
  }
}

input[type=checkbox].filter-checkbox + label:before {

}

input[type=checkbox].filter-checkbox:checked + label:before {
  content: "\f14a";
  color: $primary-blue !important;
}

.subtitle {
  color: $medium-grey;
  font-size: 13px;
}

.close-modal {
  cursor: pointer;
}