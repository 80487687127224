@import '../../styles/variables';

.pagination {
  display: flex;
  list-style: none;

  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  .page-link:disabled {
    color: $medium-grey;
  }

  .page-link:disabled:hover {
    background-color: transparent;
  }

  .page-link:not(:disabled):hover {
    background-color: $primary-blue;
    color: white;
  }

  .page-link {
    font-weight: bold;
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: $primary-blue;
    background-color: #fff;
    > span {
      font-weight: bold;
    }
  }

  .active {
    background-color: $primary-blue;
    color: white
  }
}
