@import '../../styles/variables';
@import '../../resources/fonts/style';

.menu-logo {
  max-width: 100%;
}

.bm-burger-bars {
  background: black;
}

.bm-overlay {
  z-index: 1001 !important;
}

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;

  button {
    outline: none;
  }
}

.bm-burger-bars-hover {
  background: $primary-blue;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: $primary-grey;
}

.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  height: calc(100% - 70px) !important;
}

.bm-item {
  outline: none;
  padding: 10px;
  line-height: 50px;
  font-size: 22px;
  color: $secondary-grey;
  border-bottom: 1px solid $light-grey;

  span[class^="icon-"] {
    margin-right: 15px;

    &:before {
      color: $primary-blue !important;
    }
  }

  &:hover {
    text-decoration: none;
    color: $primary-blue;
  }

  &.logout {
    background-color: $secondary-grey;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
  }
}

.footer-menu {
  display: block;
  font-size: 15px;
  line-height: normal;
  color: $primary-blue;
  margin-top: 50px;
}
